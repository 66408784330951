<template>
  <div>
    <b-card title="İzin Talebim">
      <permit-request-card :data-item="dataItem" />
    </b-card>
    <div
      v-if="dataItem.id_com_user === userData.id && dataItem.id_com_permit_request_status === '2'"
      class="text-center"
    >
      <b-button
        variant="success"
        class="text-center mr-1"
        :href="downloadPDF"
        target="_blank"
      >
        <FeatherIcon icon="PrinterIcon" />
        Yazdır

      </b-button>
    </div>
  </div>
</template>
<script>
import { BButton, BCard } from 'bootstrap-vue'
import PermitRequestCard from '@/views/Permit_request/elements/PermitRequestCard.vue'

export default {
  name: 'PermitRequestView',
  components: {
    BButton,
    PermitRequestCard,
    BCard,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['permitRequest/getData']
    },
    downloadPDF() {
      const urlParams = [
        `&id=${this.$route.params.id}`,
      ].join('&')
      return `${this.$store.state.app.baseURL}/exports/permit_request/request/pdf?token=${localStorage.getItem('downloadToken')}${urlParams}`
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('permitRequest/dataItem', this.$route.params.id)
    },
  },
}
</script>
