<template>
  <div>
    <b-row>
      <b-col>
        <b-list-group>
          <list-item
            title="Personel"
            :value="dataItem.username"
          />
          <list-item
            title="Yönetici"
            :value="dataItem.manager"
          />
          <list-item
            title="Departman"
            :value="dataItem.department"
          />
          <list-item
            title="Marka"
            :value="dataItem.brand"
          />
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <list-item
            title="Başlangıç Tarihi"
            :value="moment(dataItem.start_date).format('DD.MM.YYYY HH:mm')"
          />
          <list-item
            title="Bitiş Tarihi"
            :value="moment(dataItem.finish_date).format('DD.MM.YYYY HH:mm')"
          />
          <list-item
            title="İzin Süresi"
            :value="dataItem.days"
          />
          <list-item
            title="İzin Türü"
            :value="dataItem.type"
          />
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <list-item
            title="Yerine İlgilenecek Personel"
            :value="dataItem.relating_personal ? dataItem.relating_personal : 'Belirtilmemiş'"
          />
          <list-item
            title="Talep Durumu"
            :value="dataItem.status"
          />
          <list-item
            title="Onay Tarihi"
            :value="dataItem.adate ? moment(dataItem.adate).format('DD.MM.YYYY') : '-'"
          />
          <list-item
            title="Oluşturulma Tarihi"
            :value="dataItem.created ? moment(dataItem.created).format('DD.MM.YYYY HH:mm') : '-'"
          />
        </b-list-group>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-list-group>
          <list-item
            title="İzin Nedeni"
            :value="dataItem.permit_reason ? dataItem.permit_reason : 'Belirtilmemiş'"
          />
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BListGroup, BRow, BCol,
} from 'bootstrap-vue'
import ListItem from '@/views/Customers/view/ListItem.vue'

export default {
  name: 'PermitRequestCard',
  components: {
    ListItem,
    BListGroup,
    BRow,
    BCol,
  },
  props: {
    dataItem: {
      type: Object,
      default: () => {},
      require: true,
    },
  },
}
</script>
